.header {
  background: #fff;
  max-width: var(--maxWidth);
  width: 100%;
  border-bottom: 1px solid var(--grayscale);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--gap);

  h2 {
    font-size: var(--headlineSize);
    text-transform: uppercase;
    font-weight: var(--heavy);
    margin-left: calc(var(--gap) / 2);
    color: var(--primaryNavyBlue);
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    font-weight: var(--light);
    font-size: var(--headlineSize);
    margin-left: calc(var(--gap) / 2);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.participantheader {
  composes: header;
  position: relative;
  
  h2{
    font-size: var(--bodySize);
    color: var(--blackText);
  }
}

.backbutton {
  background: url('../assets/icons/chevron-down.svg');
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  display: block;
  margin-right: calc(var(--gap) / 2);
}
