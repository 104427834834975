.form {
  background: #fff;
  max-width: var(--maxWidth);
  --tbFormElementPad: 5px;
  width: 100%;
  padding: calc(var(--gap) / 2) 0 0;

  label {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 calc(var(--gap) / 2);
    margin: 0 var(--gap) calc(var(--gap) / 2);
    border-bottom: 1px solid var(--grayscale);

    &:last-of-type {
      // margin-bottom: 0;
      margin: 0;
      padding: 0 var(--gap) calc(var(--gap) / 2);
      // width: calc(100% + 40px);
    }
    
    span {
      font-weight: var(--normal);
      text-transform: capitalize;
      font-size: var(--bodySize);
    }

    input {
      width: 60px;
      text-align: center;
      font-weight: var(--heavy);
    }

    select {
      text-align: center;
    }
  }

  .totalscoreinput, .totalscoreinput[readonly] {
    font-weight: var(--heavy);
    border-color: var(--secondaryBlue);
    background-color: #f2f4f8;
    border-width: 2px;
  }
}
