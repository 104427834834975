.wrapper {
  max-width: var(--maxWidth);
  width: 100%;
  padding: calc(var(--gap) * 1.5) var(--gap) var(--gap);
  border-bottom: 1px solid var(--grayscale);
  composes: fake-popup-border from '../style/global.scss';
}

.imagewrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: calc(var(--gap)/2);
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--grayscale);
}

.backbutton {
  text-transform: none;
  margin-right: calc(var(--gap) / 2);
  vertical-align: text-bottom;

  &:before {
    background: url('../assets/icons/chevron-down.svg');
    transform: rotate(90deg) translateX(-2px);
    width: 20px;
    height: 20px;
    display: block;
    content: '';
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: var(--gap);
}

.h1 {
  composes: headline1 from '../style/typography.css';
  margin: 0;
}

.h2 {
  composes: subhead1 from '../style/typography.css';
  margin: 0;
}

.toputils {
  display: flex;
  justify-content: space-between;
  align-items: center;
}