:root {
  --primaryNavyBlue: #002d73;
  --secondaryBlue: #0673cc;
  --primaryLightGrey: #f3f3f3;
  --primaryRed: #cf0a2c;
  --primaryYellow: #f8d44c;
  --primaryGreen: #006647;
  --hiliteGreen: #009339;
  --white: #ffffff;
  --blackText: #2A2A2A;
  --formElementBorder: #939393;
  --mutedText: #939393;
  --grayscale: #eaeaea;
  --error: #ee0120;
  --primaryColor: var(--primaryNavyBlue);

  --primaryFont: "Source Sans Pro";
  --light: 300;
  --normal: 400;
  --heavy: 900;
  --headlineSize: 2.4rem;
  --subheadSize: 1.6rem;
  --bodySize: 1.5rem;
  --labelSize: 1.2rem;

  --gap: 20px;
  --buttonBorderWidth: 2px;
  --buttonBorderRadius: 27px;
  --elementBorderRadius: 15px;
  --elementPadding: 10px;
  --borderRadiusFormElements: 5px;
  --lrFormElementPad: 15px;
  --tbFormElementPad: 10px;
  --selectFormElementPad: 30px;
  --maxWidth: 1024px;
  --promptSpace: 0px;
  --participantIn: translateX(-100%);
  --participantOut: translateX(100%);
  --participantActive: translateX(0%);
  --participantInOpacity: 1;
  --participantOutOpacity: 1;
  --participantTransitionTime: 400ms;
  --windowScrollY: 0;
  --windowScrollYpx: 0px;
}

@media #{$mq-viewport-md} {
  :root {
    --headlineSize: 3.2rem;
    --subheadSize: 2.2rem;
    --bodySize: 1.6rem;
    --labelSize: 1.4rem;
  }
}