//MODAL PROMPT
.modal-enter {
  display: flex !important;
  position: absolute !important;
  width: 100%;
  opacity: 0;

  .inner-content {
    transform: scale(0.9);
  }
}

.modal-enter-active {
  display: flex !important;
  opacity: 1;
  transition: opacity 200ms ease-in;
  
  .inner-content {
    transform: scale(1);
    transition: all 200ms ease-in;
  }
}

.modal-enter-done {
  display: flex !important;
}

.modal-exit {
  display: flex !important;
  position: absolute !important;
  width: 100%;
  opacity: 1;
}

.modal-exit-active {
  display: flex !important;
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.modal-exit-active {
  display: none;
}


//GENERIC OPACITY TRANSITION

.opacity-transition-enter {
  position: absolute !important;
  width: 100%;
  opacity: 0;
}

.opacity-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.opacity-transition-exit {
  position: absolute !important;
  width: 100%;
  opacity: 1;
}

.opacity-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

//TRANSITION BETWEEN ROUTES (PAGES)

.route-enter {
  top: var(--windowScrollYpx);
  position: absolute !important;
  width: 100%;
  opacity: 0;
  height: 100%;
}

.route-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.route-exit {
  // top: var(--windowScrollY);
  position: absolute !important;
  width: 100%;
  opacity: 1;
  height: 100%;
}

.route-exit-active {
  // top: var(--windowScrollY);
  opacity: 0;
  transition: opacity 300ms ease-in;
}

// PARTICIPANT VIEW ROUTE TRANSITION
.participant-enter {
  position: absolute !important;
  width: 100%;
  transform: var(--participantOut);
  opacity: var(--participantOutOpacity);
}

.participant-enter-active {
  transform: var(--participantActive);
  opacity: var(--participantInOpacity);
  transition: all var(--participantTransitionTime) cubic-bezier(0.65, 0, 0.35, 1);
}

.participant-exit {
  position: absolute !important;
  width: 100%;
  transform: var(--participantActive);
  opacity: var(--participantInOpacity);
}

.participant-exit-active {
  transform: var(--participantIn);
  transition: all var(--participantTransitionTime) cubic-bezier(0.65, 0, 0.35, 1);
  opacity: var(--participantOutOpacity);
}


// ROSTER LIST ITEMS

.roster-list-enter {
  height: 0px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  opacity: 0;
  overflow: hidden;
}

.roster-list-enter-active {
  height: 80px;
  padding-top: var(--gap) !important;
  padding-bottom: var(--gap) !important;
  opacity: 1;
  overflow: hidden;
}

.roster-list-exit-active {
  opacity: 0;
  height: 0px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}

