.bar {
  margin-top: 30px;
  width: 100%;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 10px;
  background: var(--grayscale);
}

.loading {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #10d59e;
  animation-name: load;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-iteration-count: infinite;
}

.message {
  font-size: 1.3rem;
  font-weight: var(--normal);
  margin-top: 10px;
}

@keyframes load {
  0% {
    width: 1%;
  }
  100% {
    width: 100%;
  }
}