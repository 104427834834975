.uipanel {
  composes: content-pad from '../style/global.scss';
  color: var(--primaryNavyBlue);
  width: 100%;
  max-width: var(--maxWidth);

  p {
    margin-top: 0;
    font-size: var(--bodySize);
  }
}

.instructions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.skillbuttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  a {
    color: var(--primaryNavyBlue);
    font-size: var(--bodySize);
    font-weight: var(--heavy);
    text-transform: uppercase;
    text-decoration: none;
    width: calc(33% - (var(--gap) / 2));
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:visited {
      color: inherit;
    }

    div {
      margin-bottom: 10px;
    }
  }
}

.linklist {
  list-style: none;
  padding: 0;
  margin: var(--gap) 0 0;
  font-size: var(--bodySize);
  color: var(--primaryNavyBlue);

  li {
    border-top: 1px solid var(--grayscale);
    padding: var(--gap) 0;

    > a {
      color: var(--primaryNavyBlue);
      text-decoration: none;
      display: flex;
      justify-content: space-between;

      &:visited {
        color: inherit;
      }
    }
  }
}

.contactCard {
  margin-top: var(--gap);
  line-height: 1.4;
  
  strong {
    color: var(--blackText);
  }

  a {
    color: var(--secondaryBlue);
    text-decoration: none;
    
    &:visited {
      color: inherit;
    }
  }
}

.card {
  composes: card from '../style/global.scss';
}

.icon {
  background: var(--primaryNavyBlue);
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .35);
}

.form {
  width: 100%;

  button {
    min-width: 140px;
    margin: 0 auto;
    display: block;
  }
}