.toggler {
  width: calc(100% - calc(var(--gap) * 2));
  margin: var(--gap);

  label {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-bottom: 0;

    span, input[type="checkbox"] + span {
      text-transform: uppercase;
      z-index: 1;
      pointer-events: none;
      width: 50%;
      text-align: center;
      margin: 0;
      transition: color .35s cubic-bezier(0.76, 0, 0.24, 1);
    }

    .active {
      color: #fff;
    }

    .notactive {
      color: var(--primaryNavyBlue);
    }
  }

  .switch {
    &[type="checkbox"]{
      border: none;
      position: absolute;
      width: 100%;
      height: 30px;
      border-radius: var(--elementBorderRadius);
      background: #f2f4f8;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, .35);

      &:after {
        height: 30px;
        top: 0;
        width: 50%;
        border-radius: var(--elementBorderRadius);
        left: 0;
        background: var(--primaryNavyBlue);
        transition: all .35s cubic-bezier(0.76, 0, 0.24, 1);
      }

      &:checked {
        background: #f2f4f8;

        &:after {
          background: var(--primaryNavyBlue);
          transform: translateX(calc(100%));
        }
      }
    }
  }
}
