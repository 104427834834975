@import '../style/mediaqueries';

.wrapper {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: var(--promptSpace);
}

.h1 {
  composes: headline1 from '../style/typography.css';
  margin-top: 5px;
}

.subhead {
  composes: subhead1 from '../style/typography.css';
  margin: var(--gap) 0 calc(var(--gap) * 2);
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form label, .form button {
  width: 100%;
}

.form .halfsizeinput {
  width: calc(50% - (var(--gap) / 2))
}

.logo {
  max-width: 120px;
}

@media #{$mq-viewport-md} {
  .logo {
    max-width: 60%;
  }

  .h1 {
    margin-top: var(--gap);
  }
}