.FormInputAbstract {
  font-family: var(--primaryFont);
  font-size: 1.6rem;
  font-weight: var(--normal);
  border-radius: var(--borderRadiusFormElements);
  border-style: solid;
  border-width: 1px;
  border-color: var(--formElementBorder);
}

.FormInputDisabledAbstract {
  font-size: 1.6rem;
  font-weight: var(--normal);
  font-weight: 200;
  color: var(--blackText);
}

.ButtonAbstract {
  font-family: var(--primaryFont);
  @extend .label;
  padding: 13px 30px;
  cursor: pointer;
  text-align: center;
  border-width: var(--buttonBorderWidth);
  border-style: solid;
  border-radius: var(--buttonBorderRadius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: all .2s linear;
  text-transform: uppercase;
  text-decoration: none;

  &[disabled='disabled'] {
    background: var(--grayscale);
    color: var(--white);
    pointer-events: none;
    border-color: var(--grayscale);
  }
}

.button-primary {
  @extend .ButtonAbstract;
  color: var(--primaryColor);
  background-color: var(--primaryYellow);
  border-color: var(--primaryYellow);

  &:hover,
  &:focus {
    background-color: #fff;
  }
}

.button-primary-inverse {
  @extend .ButtonAbstract;
  background-color: var(--primaryColor);
  background: #fff;
  border-color: var(--primaryYellow);

  &:hover,
  &:focus {
    background-color: var(--primaryYellow);
  }
}

.label {
  font-size: var(--labelSize);
  font-weight: var(--heavy);
  text-transform: uppercase;
}

.ToggleButtonAbstract {
  cursor: pointer;
  border-radius: 12px;
  background: var(--mutedText);
  appearance: none;
  width: 40px;
  height: 24px;
  vertical-align: text-bottom;
  position: relative;
  box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,.35);
  transition: all .15s linear;

  &:focus {
    outline: none;
  }

  &:checked {
    background: var(--primaryGreen);
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #fff;
    top: 1px;
    left: 1px;
    transition: all .15s linear;
  }

  &:checked:after {
    transform: translateX(16px);
  }
}

.FancyCheckboxAbstract {
  cursor: pointer;
  background: var(--primaryYellow);
  appearance: none;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  position: relative;
  box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,.15);

  &:focus {
    outline: none;
  }

  &:after {
    display: block;
    position: absolute;
    content: "";
    width: 7px;
    height: 11px;
    top: 1px;
    left: 5px;
    visibility: hidden;
    border: 2px solid var(--primaryColor);
    border-top: none;
    border-left: none;
    transform: rotate(35deg);
  }

  &:checked:after {
    visibility: visible;
  }
}

label {
  @extend .label;
  position: relative;
  display: block;
  text-align: left;
  margin-bottom: var(--gap);
  
  &.required {
    > span:first-of-type {
      &:after {
        display: inline-block;
        content: "*";
        margin-left: 2px;
        color: var(--primaryRed);
      }
    }
  }

  &.disabled {
    color: var(--mutedText);
  }

  span {
    margin-bottom: 5px;
    display: block;

    & + input[type="text"],
    & + input[type="tel"],
    & + input[type="password"],
    & + input[type="email"],
    & + input[type="number"],
    & + input[type="url"],
    & + input[type="file"],
    & + select {
      display:block;
    }

    &.description {
      margin-bottom: var(--gap)/2;
    }
  }

  &.error {
    color: var(--error);
  }
}

textarea {
  @extend .FormInputAbstract;
  padding: var(--tbFormElementPad) var(--lrFormElementPad);
  margin-bottom: (var(--gap)/2);
  width: 100%;

  &::placeholder {
    color: var(--mutedText);
    opacity: 1;
  }
}

input {
  &:disabled {
    background-color: #fff;
    opacity: .7;
  }
  
  &[type="text"],
  &[type="tel"],
  &[type="email"],
  &[type="number"],
  &[type="url"],
  &[type="password"] {
    @extend .FormInputAbstract;
    padding: var(--tbFormElementPad) var(--lrFormElementPad);
    margin-bottom: (var(--gap)/2);
    width: 100%;

    &[readonly] {
      @extend .FormInputDisabledAbstract;
      padding: var(--tbFormElementPad) var(--lrFormElementPad);
      margin: (var(--gap)/4) 0;
    }
  }

  &[type="radio"],
  &[type="checkbox"] {
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;

    & + span {
      text-transform: none;
      margin: 0 var(--gap)/3;
      vertical-align: text-bottom;
    }

    &.ToggleInput {
      @extend .ToggleButtonAbstract;
    }
  }


  &::placeholder {
    color: var(--mutedText);
    opacity: 1;
  }

  &[type="file"] {   
    &::-webkit-file-upload-button {
      position: absolute;
      visibility: hidden;
    }

    &::file-selector-button {
      position: absolute;
      visibility: hidden;
    }
    @extend .FormInputAbstract;
    
    padding: var(--tbFormElementPad) var(--lrFormElementPad);
    width: 100%;
    position: relative;
    margin-top: var(--gap)/2;
    background: #fff;
    min-height: 42px;

    &[data-hasvalue="false"] {
      color: #fff;
    }

    & + span.placeholder {
      color: var(--mutedText);
      position: absolute;
      left: 10px;
      top: 30px;
      width: 100%;

      svg {
        width: 15px;
        height: 16px;
        margin: 0 5px;
        color: var(--primaryRed);
      }
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: "200MB Max file size";
      transform: translateY(calc(-100% - 5px));
      font-size: 1.2rem;
      color: var(--blackText);
    }
  }

  &[type="submit"] {
    @extend .ButtonAbstract;
    @extend .label;
    @extend .button-primary;
    width: 100%;
    border-radius: var(--borderRadius);

    &[disabled] {
      background: var(--mutedText);
      color: #fff;
      border-color: var(--mutedText);
      cursor: default;
    }
  }
}

select {
  @extend .FormInputAbstract;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: var(--tbFormElementPad) var(--selectFormElementPad) var(--tbFormElementPad) var(--lrFormElementPad);
  cursor: pointer;
  appearance: none;
  position: relative;
  background-size: 24px;
  background-position: top 53% right 6px;
  background-repeat: no-repeat;
  background-image: url('../assets/icons/chevron-down.svg');
  background-color: #fff;
  width: 100%;
  margin-bottom: (var(--gap)/2);

  &:invalid {
    color: var(--mutedText);
  }
}

button {
  @extend .ButtonAbstract;
  @extend .button-primary;

  &[disabled] {
    opacity: .5;
  }
}

.not-button {
  border: 0;
  background: none;
  padding: 0;
  font-size: 1.4rem;
  font-weight: var(--normal);
}