.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: var(--maxWidth);
  align-items: center;
  composes: content-pad from '../style/global.scss';
  border-bottom: 1px solid var(--grayscale);
}

.imagewrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: calc(var(--gap)/2);
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.wrapper span {
  font-size: 1.1rem;
  font-weight: var(--heavy);
  text-transform: uppercase;
  line-height: 1rem;
  display: block;
}

.h2 {
  margin: 3px 0;
  color: var(--primaryNavyBlue);
  font-size: 1.6rem;
}
