.participantButtons {
  display: flex;
  justify-content: space-between;

  button:first-child {
    text-align: left;
  }

  button:last-child {
    text-align: right;
  }
}

.utilNav {
  width: 100%;
}


.saveButton, .helplink {
  padding: calc(var(--gap) / 2) var(--gap); // calc(var(--gap) * 2)
  width: 100%;
  max-width: var(--maxWidth);
  transform: translateY(0px);

  button {
    width: 100%;
  }

  &.stickyButton {
    position: fixed;
    top: calc(100vh - var(--promptSpace));    
    z-index: 100;
    background: #fff;
    transform: translateY(-100%);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    transition: box-shadow .3s cubic-bezier(0.76, 0, 0.24, 1);
  }

  &.belowFold {
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
}

.helplink {
  text-align: right;
}

.panel {
  width: 100%;
  position: absolute;
  left: 0;
  padding-bottom: var(--promptSpace);
}

.stickyButtonPad {
  padding-bottom: calc(62px + var(--promptSpace));
}

.panelwrapper {
  max-width: var(--maxWidth);
  width: 100%;
  position: relative;
}