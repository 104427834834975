@import 'mediaqueries';
@import 'vars';
@import 'normalize.css';
@import 'typography.css';
@import 'formelements';
@import 'transitions';

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--primaryFont), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem; /*default size of 16pt*/
}

* {
  box-sizing: border-box;
}

#root {
  padding-bottom: var(--promptSpace);
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.content-wrapper {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content-pad {
  padding: var(--gap);
}

.content-top {
  flex-direction: column;
  min-height: 0px;
  max-width: 800px;
  position: relative;
}

.card {
  padding: 21px 25px 20px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #eaeaea;
}

.smallIcons {
  .skillicon {
    height: 30px;
    width: 30px;
    padding: 5px;
    &:after {
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
}

.skillicon {
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    width: 32px;
    height: 32px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.all {
    background: var(--primaryNavyBlue);
    &:after {
      background-image: url('../assets/icons/dcp-logo-notext.svg');
    }
  }

  &.drive {
    background: var(--primaryRed);
    &:after {
      background-image: url('../assets/icons/drive-icon.svg');
    }
  }

  &.chip {
    background: var(--primaryYellow);
    &:after {
      background-image: url('../assets/icons/chip-icon.svg');
    }
  }

  &.putt {
    background: var(--primaryGreen);
    &:after {
      background-image: url('../assets/icons/putt-icon.svg');
    }
  }
}

.error-message {
  border: 1px dotted var(--primaryRed);
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: var(--light);
  color: var(--primaryRed);
}

.needhelp-link {
  font-size: 1.2rem;
  text-decoration: none;
  color: var(--secondaryBlue);

  &:before {
    content: '?';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--secondaryBlue);
    margin-right: 3px;
    font-family: sans-serif;
  }

  &.pull-right {
    position: absolute;
    right: var(--gap);
  }
}

.blue-bg {
  background: var(--primaryNavyBlue);
}

.fake-popup-border {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: var(--primaryNavyBlue);
    content: '';
    z-index: -2;
  }

  &:after {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    content: '';
    z-index: -1;
  }
}

.error-text {
  color: var(--primaryRed);
}