.link {
  composes: not-button from '../style/formelements.scss';
  text-decoration: none;
  display: block;
  padding: var(--gap);
  width: 100%;
  font-size: var(--labelSize);
  border: 1px solid var(--grayscale);
  border-radius: 0px;

  span {
    display: block;
  }
}

.label {
  font-weight: var(--heavy);
}

.name {
  font-weight: var(--heavy);
  text-transform: none;
  font-size: var(--labelSize);
  margin: 5px 0;
}

.time {
  color: var(--blackText);
}