.headline1 {
  font-size: var(--headlineSize);
  font-weight: var(--normal);
  color: var(--primaryColor);
}

.subhead1 {
  font-size: var(--subheadSize);
  font-weight: var(--light);
  color: var(--blackText);
}

.label1 {
  font-size: var(--labelSize);
  font-weight: var(--heavy);
  text-transform: uppercase;
}

.label2 {
  font-size: var(--bodySize);
  font-weight: var(--heavy);
  text-transform: uppercase;
}

p {
  font-size: var(--bodySize);
  color: var(--primaryNavyBlue);
  font-weight: var(--normal);
}