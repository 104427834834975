.divisionwrapper {
  max-width: var(--maxWidth);
  width: 100%;
  padding: calc(var(--gap) / 2) var(--gap);
}

.divisions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px var(--grayscale);
  border-radius: 5px;
}

.genderDivision {
  width: 50%;
  text-align: center;
  margin: 0;

  dd {
    padding: 0;
  }

  dt {
    padding: var(--gap) 0;
  }
  
  dd, dt {
    margin: 0;
    text-align: center;
    border-bottom: 1px solid var(--grayscale);
    border-right: 1px solid var(--grayscale);

    a {
      padding: var(--gap) 0;
      display: block;
      color: var(--primaryNavyBlue);
      width: 100%;
      text-decoration: none;
      &:visited {
        color: inherit;
      }
    }
  }
}

.divisionHeader {
  composes: label2 from '../style/typography.css';
  color: var(--primaryNavyBlue);
}

.instructions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}