.about {
  width: 100%;
  max-width: var(--maxWidth);
  text-align: center;
  padding: var(--gap) calc(var(--gap) * 2);
  composes: fake-popup-border from '../style/global.scss';
  margin: 0 auto;

  a {
    margin: 0 10px;
  }
}

.closeBtn {
  position: absolute;
  top: calc(var(--gap) * 1.5);
  right: var(--gap);
  width: 30px;
  height: 30px;
  border: 1px solid var(--primaryNavyBlue);
  border-radius: 50%;
  text-decoration: none;
  color: var(--primaryNavyBlue);
  padding-top: 6px;

  &:visited, &:active {
    color: inherit;
  }
}