.dropdown {
  margin-top: calc(var(--gap) / 2);
  position: relative;

  select {
    width: 150px;
    height: 30px;
    border-radius: 15px;
    text-transform: uppercase;
    // font-size: 1.1rem;
    font-weight: var(--heavy);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    background-image: url('../assets/icons/chevron-down.svg');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: top 53% right 6px;
    border: 1px solid var(--grayscale);
    color: var(--primaryNavyBlue);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .1);
    font-size: 1.6rem;
  }
  
  &.checked-in {
    select {
      background-image: url('../assets/icons/chevron-down-white.svg');
      background-color: #00a673;
      position: relative;
      color: #fff;
      border: 1px solid #00a673;
      padding-left: 30px;
    }
    
    &:before {
      content: '';
      position: absolute;
      background: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      z-index: 1;
      left: 10px;
      top: 7px;
    }

    &:after {
      content: 'L';
      position: absolute;
      transform: scaleY(-1) rotate(140deg);
      color: #00a673;
      font-weight: var(--heavy);
      left: 15px;
      bottom: 8px;
      font-size: 1.2rem;
      z-index: 2;
    }
  }

  .quickview {
    margin-right: 5px;
    border: none;
    width: 2px;

    &.checked-in {
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      
      &:after {
        color: #00a673;
      }
    }
  }
}

.quickview {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--grayscale);

  &.checked-in {
    background-color: #00a673;
    position: relative;
    border: 1px solid #00a673;

    &:after {
      content: 'L';
      position: absolute;
      transform: scaleY(-1) rotate(140deg);
      color: #fff;
      font-weight: var(--heavy);
      left: 4px;
      bottom: 1px;
      font-size: 1.2rem;
    }
  }
}
