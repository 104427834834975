@import '../style/mediaqueries';

.base {
  display: flex;
  margin-left: 8px;
  align-items: center;

  &:after{
    content: '';
    height: 13px;
    width: 13px;
    background: var(--primaryRed);
    border: 2px solid #fff;
    border-radius: 50%;
    transition: all .4s linear;
  }
}

.online:after{
  content: '';
  background: #10d59e;
}

.synching:after {
  content: '';
  width: 15px;
  height: 15px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23cccccc' stroke-width='4' stroke-dasharray='90%25%2c 10%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border: none;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-iteration-count: infinite;
}

.error:after {
  content: '!';
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--heavy);
  font-size: 1.1rem;
}

.status {
  text-transform: uppercase;
  font-size: var(--labelSize);
  font-weight: var(--heavy);
  margin-right: 5px;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}