.headerwrapper {
  padding: calc(var(--gap) * .75) var(--gap);
  background: var(--primaryNavyBlue);
  color: #fff;
  width: 100%;
  text-align: center;
}

.header {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--maxWidth);
}

.logo {
  max-width:30vw;
}

.installButton {
  cursor: pointer;
  border-width: var(--buttonBorderWidth);
  border-style: solid;
  border-radius: var(--buttonBorderRadius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .2s linear;
  min-width: 120px;
  padding: var(--elementPadding);
  color: var(--primaryNavyBlue);
  background-color: var(--primaryYellow);
  border-color: var(--primaryYellow);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1;
}

.installButton:hover {
  background-color: #fff;
}

.hidden {
  display: none;
}

.utils {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}