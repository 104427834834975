.header {
  width: 100%;
  background: var(--primaryNavyBlue);
}

.headerinner {
  padding: var(--gap);
  max-width: var(--maxWidth);
  display: flex;
  margin: 0 auto;
}

.backbutton {
  text-transform: none;
  margin-right: calc(var(--gap) / 2);
  vertical-align: text-bottom;

  &:hover {
    background: none;
  }
  
  &:before {
    background: url('../assets/icons/chevron-down-white.svg');
    transform: rotate(90deg) translateX(-2px);
    width: 20px;
    height: 20px;
    display: block;
    content: '';
  }
}

.participantName {
  color: var(--primaryNavyBlue);
}

.rosterList {
  >li {
    transition: all .3s linear;
    overflow: hidden;

    a {
      text-decoration: none;
      color: inherit;
      
      &:visited {
        color: inherit;
      }
    }

    ul {
      li:first-child {
        width: auto;
        padding-right: calc(var(--gap) / 2);
      }
    }
  }
}

.divisionTitle {
  display: block;
  font-size: 1.2rem;
  margin-top: 4px;
}