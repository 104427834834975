.wrapper {
  width: 100%;
}

.list {
  --tbFormElementPad: 5px;
  list-style: none;
  margin: 0;
  padding: 0;
  
  li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grayscale);

    &:not(:first-child){
      padding: calc(var(--gap) / 2) 0;
      margin: 0 var(--gap);
      justify-content: space-between;
    }

    input, input[readonly] {
      width: 60px;
      text-align: center;
      border-width: 2px;
      border-color: var(--secondaryBlue);
      font-weight: var(--heavy);
      background: #f2f4f8;
    }

    &:last-child {
      margin: 0;
      padding: calc(var(--gap) / 2) var(--gap);

      input, input[readonly] {
        background: var(--secondaryBlue);
        color: #fff;
      }
    }
  }
}

.header {
  background: #fff;
  max-width: var(--maxWidth);
  width: 100%;
  border-bottom: 1px solid var(--grayscale);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--gap);
  margin: 0;

  h2{
    text-transform: uppercase;
    font-weight: var(--heavy);
    font-size: var(--bodySize);
    margin: 0;
  }
  
  div {
    margin-right: calc(var(--gap) / 2);
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: var(--bodySize);
  
  div {
    margin-right: calc(var(--gap) / 2);
  }
}