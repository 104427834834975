.participantlist {
  max-width: var(--maxWidth);
  list-style: none;
  width: 100%;
  margin: 0; 
  padding: 0;

  >li {
    margin: 0;
    padding: var(--gap);
    border-bottom: 1px solid var(--grayscale);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      li {
        margin: 0;
        padding: 0;
        width: 15%;

        &:first-child {
          width: 10%;
        }
        
        &:last-child {
          text-align: center;
        }

        &:nth-child(2) {
          flex-grow: 2;
        }
      }
    }
  }
}

.participantHeaders {
  composes: label1 from '../style/typography.css';
}

.participantResults {
  font-size: 1.4rem;

  a {
    strong {
      font-weight: var(--heavy);
    }
    color: var(--primaryNavyBlue);
    text-decoration: none;

    &:visited {
      color: var(--primaryNavyBlue);
    }
  }
}