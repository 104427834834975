.wrapper {
  position: absolute;
  top: var(--windowScrollYpx);
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0,0,0,.7);
  justify-content: center;
  align-items: center;
  display: none;
}

.content {
  background: #fff;
  border-radius: 25px;
  padding: var(--gap);
  width: 80%;
  text-align: center;
  transition: all .2s linear;
  
  button {
    margin-top: var(--gap);

    &:first-of-type {
      margin-right: 10px;
    }
  }
}

.h1 {
  composes: headline1 from '../style/typography.css';
}
