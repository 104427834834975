@import '../style/mediaqueries';

.wrapper {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;

  button:first-child {
    margin-right: 10px;
  }
}

.logo {
  max-width: 120px;
}

.subhead {
  position: relative;
  text-align: center;
  max-width: 50vw;
  margin: 0 auto calc(var(--gap) * 1.5);
  
  &:before {
    margin: var(--gap) auto calc(var(--gap) * .75);
    width: 20px;
    height: 20px;
    background: var(--primaryRed);
    content: '!';
    color: #fff;
    font-weight: var(--heavy);
    text-align: center;
    border-radius: 50%;
    display: block;
  }
}

@media #{$mq-viewport-md} {
  .logo {
    max-width: 60%;
  }
}