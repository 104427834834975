.wrapper {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.h1 {
  composes: headline1 from '../style/typography.css';
  composes: h1 from './Login.module.scss';
}

.subhead {
  composes: subhead1 from '../style/typography.css';
  composes: subhead from './Login.module.scss';
}

.logo {
  composes: logo from './Login.module.scss';
}
