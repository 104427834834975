.scoreoptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  select {
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    min-height: 32px;
    width: 65%;
    overflow: hidden;
  }

  .input, .input[readonly] {
    margin-left: calc(var(--gap) / 2);
    width: 60px;
    text-align: center;
    border-color: var(--secondaryBlue);
    font-weight: var(--heavy);
  }

  .optiondescription {
    font-size: 1.2rem;
  }
}

.selecthidden {
  opacity: 0;
}

.selectvisible {
  opacity: 1;
}
