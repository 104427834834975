.ath {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: var(--gap);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); 
  background: #fff;
  text-align: center;
  transition: all .2s linear;
}

.athios {
  position: fixed;
  left: 2vw;
  bottom: 10px;
  width: 96vw;
  padding: calc(var(--gap) / 2);
  border-radius: 10px;
  border: 1px solid var(--grayscale);
  background: #fff;
  text-align: center;
  transition: all .2s linear;

  p {
    margin: 0 var(--gap);
    font-size: 1.2rem;
  }

  .closebtn {
    top: calc(var(--gap) / 2);
  }

  &.iphone {
    bottom: 18px;

    &:before {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border-top: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 0px solid transparent;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    &:after {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border-top: 11px solid var(--grayscale);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 0px solid transparent;
      position: absolute;
      bottom: -11px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }

  &.hide {
    display: none;
  }
}

.iosicon {
  width: 12px;
  vertical-align: text-bottom;
  margin: 0 2px;
}

.show {
  transform: translateY(0%);
}

.hide {
  transform: translateY(100%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
}

.closebtn {
  position: absolute;
  top: var(--gap);
  right: var(--gap);
  background: none;
  border: none;
  padding: 0;
  color: var(--blackText);
  font-weight: var(--light);
  font-size: 1.4rem;
  transform: scaleX(1.5);
}